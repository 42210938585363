'use strict';

const _ = require('lodash');
const AbstractDialect = require('../abstract');
const ConnectionManager = require('./connection-manager');
const Query = require('./query');
const QueryGenerator = require('./query-generator');
const DataTypes = require('../../data-types').mssql;
const { MSSqlQueryInterface } = require('./query-interface');

class MssqlDialect extends AbstractDialect {
  constructor(sequelize) {
    super();
    this.sequelize = sequelize;
    this.connectionManager = new ConnectionManager(this, sequelize);
    this.queryGenerator = new QueryGenerator({
      _dialect: this,
      sequelize
    });
    this.queryInterface = new MSSqlQueryInterface(
      sequelize,
      this.queryGenerator
    );
  }
}

MssqlDialect.prototype.supports = _.merge(
  _.cloneDeep(AbstractDialect.prototype.supports),
  {
    DEFAULT: true,
    'DEFAULT VALUES': true,
    'LIMIT ON UPDATE': true,
    'ORDER NULLS': false,
    lock: false,
    transactions: true,
    migrations: false,
    returnValues: {
      output: true
    },
    schemas: true,
    autoIncrement: {
      identityInsert: true,
      defaultValue: false,
      update: false
    },
    constraints: {
      restrict: false,
      default: true
    },
    index: {
      collate: false,
      length: false,
      parser: false,
      type: true,
      using: false,
      where: true
    },
    NUMERIC: true,
    tmpTableTrigger: true
  }
);

MssqlDialect.prototype.defaultVersion = '12.0.2000'; // SQL Server 2014 Express, minimum supported version
MssqlDialect.prototype.Query = Query;
MssqlDialect.prototype.name = 'mssql';
MssqlDialect.prototype.TICK_CHAR = '"';
MssqlDialect.prototype.TICK_CHAR_LEFT = '[';
MssqlDialect.prototype.TICK_CHAR_RIGHT = ']';
MssqlDialect.prototype.DataTypes = DataTypes;

module.exports = MssqlDialect;
